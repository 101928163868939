import React from "react"
import Seo from "../components/seo"
import Navbar from "../components/navbar"
import Alimentos from "../components/alimentos"
import Footer from "../components/footer"
import "../css/style.css"
// import Popup from "../components/Popup"

const AlimentosPage = () => (
  <>
    <Seo title="Platillos" />
    <Navbar />
    {/* <Popup/> */}
    <Alimentos />
    <div className="footer-3">
      <Footer />
    </div>
  </>
)

export default AlimentosPage
